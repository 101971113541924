import LogoUrl from './assets/images/logo.png';

export const businessInfo = {
  name: 'Dirt For Dough',
  phone: '330-223-5263',
  email: 'hello@dirtfordough.com',
  logoUrl: LogoUrl
};

export const faqs = [
  {
    id: 0,
    question: 'How long does it take to sell my land and get the money?',
    answer: `Once you accept our offer and sign our purchase agreement, we can usually close within a 1-3 week time frame, depending on the transaction value and title work. We use mobile notaries in your area or title companies to assist with the close, and we pay for all of those costs.`,
  },  
  {
    id: 1,
    question: 'Are there any fees to sell my land to you?',
    answer: `There are no fees at all to work with us. The offer we make to you is the amount you receive at closing. We make our money through the property investment itself and don’t need to charge you a fee.`,
  },
  {
    id: 2,
    question: 'How do you pay me for my land?',
    answer: `Payment will be sent to you immediately at closing by check or bank account (ACH) transfer.`,
  },
  {
    id: 3,
    question: 'Am I obligated to sell my land once you make me an offer?',
    answer: `Absolutely not. Our offer comes with no obligation to accept it.`,
  },
  {
    id: 4,
    question: 'What is the process?',
    answer: `The process is simple. You can either fill out our offer form on this website, or give us a call at ${businessInfo.phone}. You provide us with some information about the land you want to sell, then we’ll do some quick research on the land to make you an offer.`,
  },
  {
    id: 5,
    question: 'Do you buy land in any location?',
    answer: `Right now we buy land anywhere in the United States. Do you have more than one parcel you want to sell? Let us know.`,
  },
  {
    id: 6,
    question: 'How long does it take to get an offer?',
    answer: `We will have an offer to you as soon as possible, usually no longer than 48 hours.`,
  },
];