import React from 'react';

function Benefits() {
  return (
    <section class="bg-gray-50 overflow-hidden py-8">
      <div class="relative max-w-screen-xl mx-auto py-8 md:py-12 px-4 sm:px-6 lg:px-8">        
        <div class="relative lg:grid lg:grid-cols-3 lg:col-gap-8">
          <div class="lg:col-span-1">
            <h3 class="text-3xl text-center md:text-left leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Benefits of Selling Your Land to Us
            </h3>
          </div>
          <div class="mt-10 sm:grid sm:grid-cols-3 sm:col-gap-8 sm:row-gap-10 lg:col-span-2 lg:mt-0">
            <div className="flex justify-base">
              <div class="mr-2 mt-2 flex items-center justify-center h-12 w-12 rounded-md text-green-600">
                <svg className="h-8 w-8 md:h-10 md:w-10" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M5 13l4 4L19 7"></path>
                </svg>
              </div>
              <div class="mt-5">
                <h4 class="text-xl md:text-2xl leading-6 font-medium text-gray-900">
                  Cash
                </h4>
                <p class="mt-2 text-lg leading-6 text-gray-500">
                  We are cash buyers – no waiting on banks or lender approvals.
                </p>
              </div>
            </div>
            <div class="mt-4 sm:mt-0 flex justify-base">
              <div class="mr-2 mt-2 flex items-center justify-center h-12 w-12 rounded-md text-green-600">
                <svg className="h-8 w-8 md:h-10 md:w-10" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M5 13l4 4L19 7"></path>
                </svg>
              </div>
              <div class="mt-5">
                <h4 class="text-xl md:text-2xl leading-6 font-medium text-gray-900">
                  Fast
                </h4>
                <p class="mt-2 text-lg leading-6 text-gray-500">
                  We can complete the purchase in as little as one week.
                </p>
              </div>
            </div>
            <div class="mt-4 sm:mt-0 flex justify-base">
              <div class="mr-2 mt-2 flex items-center justify-center h-12 w-12 rounded-md text-green-600">
                <svg className="h-8 w-8 md:h-10 md:w-10" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M5 13l4 4L19 7"></path>
                </svg>
              </div>
              <div class="mt-5">
                <h4 class="text-xl md:text-2xl leading-6 font-medium text-gray-900">
                  No Hidden Fees
                </h4>
                <p class="mt-2 text-lg leading-6 text-gray-500">
                  No commissions, No fees, and we cover ALL closing costs
                </p>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </section>
  )
}

export default Benefits;
