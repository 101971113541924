import React from 'react';

function EmailCapture({ backgroundColor = "bg-gray-100" }) {
  return (
    <>        
      <div className={`relative mt-5 md:mt-8 text-left rounded-md overflow-hidden shadow-md`} id="email-capture">
        {/* <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
        </div> */}
        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          <div className={`${backgroundColor} bg-opacity-93 py-8 md:py-16 pb-4 md:pb-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12`} id="email-capture-desc">
            <div className="max-w-lg mx-auto">
              <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-green-600 sm:text-3xl sm:leading-9">
                Start Here! Fill out our form.
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-700">
                We will send you a no-obligation cash offer.
              </p>
              <p className="mt-3 text-lg leading-6 text-gray-700">
                We do NOT charge any fees and we cover ALL of the closing costs.
              </p>              
            </div>
          </div>

          <div className="bg-white bg-opacity-93 py-8 md:py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12" id="email-capture-form">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <form action="https://ashbrookra.activehosted.com/proc.php" method="POST" id="_form_1_" className="grid grid-cols-1 row-gap-3 md:row-gap-6 _form _form_1 _form-content" noValidate>
                <input type="hidden" name="u" value="1" />
                <input type="hidden" name="f" value="1" />
                <input type="hidden" name="s" />
                <input type="hidden" name="c" value="0" />
                <input type="hidden" name="m" value="0" />
                <input type="hidden" name="act" value="sub" />
                <input type="hidden" name="v" value="2" />

                <div>
                  <label htmlFor="full_name" className="sr-only">
                    Full name
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">                    
                      <svg className="h-5 w-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
                    </div>
                    <input required name="fullname" id="full_name" className="form-input block w-full py-3 px-4 pl-10 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Full name" />
                  </div>
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone Number
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">                    
                      <svg className="h-5 w-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
                    </div>
                    <input required name="phone" id="phone" className="form-input block w-full py-3 px-4 pl-10 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Phone" />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"/>
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"/>
                      </svg>
                    </div>
                    <input required name="email" id="email" type="email" className="form-input block w-full py-3 px-4 pl-10 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Email" />
                  </div>
                </div>
                <div className="state-field">
                  <label htmlFor="state" className="sr-only">
                    State
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"/>
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"/>
                      </svg>
                    </div>
                    <input required name="field[4]" id="state" type="text" className="form-input block w-full py-3 px-4 pl-10 placeholder-gray-500 transition ease-in-out duration-150" placeholder="State" />
                  </div>
                </div>
                <div>
                  <label htmlFor="county" className="sr-only">
                    County
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path><path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                    </div>
                    <input required name="field[2]" id="county" type="text" className="form-input block w-full py-3 px-4 pl-10 placeholder-gray-500 transition ease-in-out duration-150" placeholder="County" />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Parcel Number
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                    </div>
                    <input required name="field[3]" id="parcel_number" type="text" className="form-input block w-full py-3 px-4 pl-10 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Parcel Number" />
                  </div>
                </div>
                <div className="">
                  <span className="inline-flex w-full md:w-auto rounded-md shadow-sm">
                    <button id="_form_1_submit" type="submit" className="w-full md:w-auto inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out">
                      Get Your Cash Offer!
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailCapture;