import React from 'react'

function HowItWorks() {
  return (
    <section className="py-10 md:py-32 bg-white border-t-4 border-b-4 border-green-500">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl sm:leading-10">
            How It Works
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            This is how easy it is to get CASH for your land:
          </p>
        </div>

        <div className="mt-10">
          <ul className="md:grid md:grid-cols-1 md:col-gap-8 md:row-gap-16 md:max-w-2xl mx-auto">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 md:h-16 md:w-16 rounded-md bg-green-600 text-white">                    
                    <svg className="h-8 w-8" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-xl md:text-2xl leading-6 font-medium text-gray-900">
                    1. You fill out the form
                  </h4>
                  <p className="mt-2 text-md md:text-lg leading-6 text-gray-500">
                    <strong>Submit your property via the form on our page, OR give us a call!</strong> We review the information you provide to determine a fair offer for your land.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 md:h-16 md:w-16 rounded-md bg-green-600 text-white"> 
                    <svg className="h-8 w-8" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-xl md:text-2xl leading-6 font-medium text-gray-900">
                    2. We send you an offer
                  </h4>
                  <p className="mt-2 text-md md:text-lg leading-6 text-gray-500">
                    <strong>We will contact you</strong> by phone or email to discuss our offer with you. We’ll prepare a purchase agreement and send it to you to sign.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 md:h-16 md:w-16 rounded-md bg-green-600 text-white">
                    <svg className="h-8 w-8" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-xl md:text-2xl leading-6 font-medium text-gray-900">
                    3. We close on the property
                  </h4>
                  <p className="mt-2 text-md md:text-lg leading-6 text-gray-500">
                    Once you sign our purchase agreement, we will begin the closing process and prepare a deed of transfer. <strong>Payment will be sent to you by check or bank account (ACH) transfer.</strong>
                  </p>
                </div>
              </div>
            </li>            
          </ul>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks
