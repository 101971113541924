import React from 'react';
import Home from './pages/Home';
import Footer from './components/Footer';

import './css/tailwind.css';
import './css/styles.css';

function App() {
  return (
    <div className="App">
      <Home />
      <Footer />
    </div>
  );
}

export default App;
