import React from 'react';
import { businessInfo } from '../../site.config';

function About() {
  const { phone, email } = businessInfo;

  return (
    <section className="relative bg-gray-50 overflow-hidden py-0 md:py-24" id="about-section">
      <div className="relative max-w-screen-xl rounded-md overflow-hidden mx-auto text-white py-16 px-6 sm:px-6 lg:px-24 lg:py-24" id="about-container">
        <h4 className="text-2xl leading-8 font-bold tracking-tight sm:text-3xl sm:leading-9">
          About Our Company
        </h4>
        <p className="mt-5 mt-3 text-lg leading-8">
          We are a small family business based out of Ohio, and we have been buying and selling land all around the United States since 2016. We take pride in offering a fast, convenient way for you to part with your unused property and receive cash. Whatever the reason is that you want to sell, our goal is to make the process easy, fair and accessible to everyone. We will see to it that you are satisfied with the sale of your property!
        </p>
        <p className="mt-5 mt-3 text-lg leading-8">
          <em>
            We are real people and are always available for questions. Give us a call or send an email today!
          </em>
        </p>
        <h5 className="mt-5 font-bold text-xl leading-8">
          Contact Us
          <br />
          { phone }
          <br />
          { email }
        </h5>
      </div>
    </section>
  )
}

export default About;
