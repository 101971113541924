import React from 'react';

import { faqs } from '../../site.config';

function FAQ() {
  return (
    <section class="bg-gray-50">
      <div class="max-w-screen-xl mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
        <h3 className="mt-2 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl sm:leading-10">
          Frequently Asked Questions
        </h3>
        <p className="mt-4 max-w-2xl text-center text-xl leading-7 text-gray-500 lg:mx-auto">
          We want to buy your land, whatever the situation. Below are a few of our most frequently asked questions.
        </p>
        <div class="mt-0 md:mt-6 border-t-2 border-gray-100 pt-10">
          <dl class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              {faqs.filter((faq, index, faqs) => faqs.length % 2 === 0 ? (faq.id < (faqs.length / 2)) : (faq.id <= (faqs.length / 2))).map((faq, index) => {
                return (
                  <div className={`${index === 0 ? '' : 'mt-10 md:mt-12'}`}>
                    <dt class="text-xl leading-6 font-medium text-gray-900">
                      {faq.question}
                    </dt>
                    <dd class="mt-2">
                      <p class="text-lg leading-6 text-gray-500">
                        {faq.answer}
                      </p>
                    </dd>
                  </div>
                );
              })}
            </div>
            <div class="mt-10 md:mt-0">
              {faqs.filter((faq, index, faqs) => faqs.length % 2 === 0 ? (faq.id >= (faqs.length / 2)) : (faq.id > (faqs.length / 2))).map((faq, index) => {
                return (
                  <div className={`${index === 0 ? '' : 'mt-10 md:mt-12'}`}>
                    <dt class="text-xl leading-6 font-medium text-gray-900">
                      {faq.question}
                    </dt>
                    <dd class="mt-2">
                      <p class="text-lg leading-6 text-gray-500">
                        {faq.answer}
                      </p>
                    </dd>
                  </div>
                );
              })}
            </div>
          </dl>
        </div>
      </div>
    </section>
  )
}

export default FAQ;