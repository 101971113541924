import React from 'react';
import { getResponsiveImgUrl } from '../../utils/imageUtils';

import EmailCapture from '../../components/EmailCapture';
import FeatureBGSm from '../../assets/images/feature-bg-sm.jpg';
import FeatureBGLg from '../../assets/images/feature-bg-lg.jpg';

function Feature() {
  return (
    <section className="relative border-t-4 border-b-4 border-green-500 py-8 md:py-16">
      <div className="block absolute inset-y-0 h-full w-full" id="bg-image">
        <img className="h-full w-full object-cover object-top" src={getResponsiveImgUrl(FeatureBGSm, FeatureBGLg)} alt="Dirt For Dough Email Capture Background"/>        
      </div>

      <div className="flex flex-col justify-center items-center px-1 md:px-0">
        <EmailCapture />
      </div>
    </section>
  )
}

export default Feature
