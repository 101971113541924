import React from 'react';
import Hero from './home/Hero';
import HowItWorks from './home/HowItWorks';
import Feature from './home/Feature';
import Benefits from './home/Benefits';
import FAQ from './home/FAQ';
import About from './home/About';


function Home() {
  return (
    <main>
      <Hero />
      <Benefits />
      <HowItWorks />
      <FAQ />
      <Feature />
      {/* in future sites, move the Feature component ABOVE the FAQ component */}
      <About />
    </main>
  )
}

export default Home;
