import React from 'react';
import { businessInfo } from '../../site.config';
import { getResponsiveImgUrl } from '../../utils/imageUtils';

import HeroBGLg from '../../assets/images/hero-bg-2-lg.jpg';
import HeroBGSm from '../../assets/images/hero-bg-2-sm.jpg';
import EmailCapture from '../../components/EmailCapture';

function Hero() {
  const { name, phone, logoUrl } = businessInfo;

  return (
    <header className="relative bg-gray-50 overflow-hidden lg:min-h-screen" id="hero-section">
      <div className="absolute inset-y-0 h-full w-full" id="bg-image">
        <img className="h-full w-full object-cover object-top" src={getResponsiveImgUrl(HeroBGSm, HeroBGLg)} alt="Dirt For Dough Land Background"/>        
      </div>

      <div className="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
          <nav className="relative flex flex-col items-center justify-between sm:h-10 md:flex-row md:justify-center">
            <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a className="-mt-16 md:mt-0" href="/" aria-label="Home">
                  { logoUrl 
                    ? <img className="h-64 w-auto sm:h-80" src={logoUrl} alt="Logo" />
                    : <h4 className="text-3xl font-semibold text-green-700">
                        { name }
                      </h4>
                  }
                </a>
              </div>
            </div>
            <div className="hidden md:block">
            </div>
            <div className="-mt-20 md:mt-0 md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
              <a href={`tel:${phone}`}className="inline-flex rounded-md shadow">
                <span className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-primary bg-white hover:bg-gray-50 focus:outline-none focus:border-green-300 focus:shadow-outline-green active:bg-gray-50 active:text-green-700 transition duration-150 ease-in-out">
                  <svg className="mr-2 h-5 w-5 text-primary" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
                  </svg>
                  Call us! { phone }
                </span>
              </a>
            </div>
          </nav>
        </div>

        <main className="mt-10 mx-auto max-w-screen-xl px-1 md:px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
          <div className="text-center">
            <h2 className="lg:-mt-16 text-4xl tracking-tight leading-10 font-bold text-green-600 sm:text-5xl sm:leading-none">
              Looking to Sell Your Land for Cash?
            </h2>
            <p className="mt-3 max-w-md mx-auto text-base font-semibold sm:text-lg md:mt-5 md:text-xl md:max-w-3xl md:text-2xl text-white md:text-primary">
              Turn Your Unused Dirt into Dough!
            </p>
            
            <EmailCapture />
          </div>
        </main>
      </div>
    </header>
  )
}

export default Hero;
